import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PlanViewer from './PlanViewer';
const Plan = () => {
  const { url } = useParams();
  useEffect(() => {
    console.log('BEFORE ENCODE INSIDE DOC VIEW');
    console.log(url);
  }, [url]);
  return (
    <div id='plans'>
      <PlanViewer url={url != null ? atob(decodeURI(url)) : 'none'} />
    </div>
  );
};

export default Plan;
