import React, { useEffect } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { isDesktop } from 'react-device-detect';
import QRCode from 'react-qr-code';
const Contract = () => {
  const [params, setParams] = React.useState('');
  const idParam = useParams();
  //   const iframe = `<iframe
  //    id='contract'
  //    title='Sign'
  //    height='750px'
  //    frameborder='0'
  //    allowTransparency='true'
  //    scrolling='auto'
  //    src=${
  //      'https://grroofing.app/damjan_geoffreynolds/grroofing/form-embed/Contracts_Confimed/p0ERrFaxSFwxJMeXuwQWzQ9EYQssneCFDqC5u6GS3hQOpbgtB334YK3nzCxMC8p62118wwjUCHN31s7eQTfTem5x9hyZuKb7j1UC?contractNumber=' +
  //      idParam.idThis
  //    }
  //  ></iframe>`;
  const iframe = `<iframe height='500px' width='100%' frameborder='0' allowTransparency='true' scrolling='auto' src='https://grroofing.app/damjan_geoffreynolds/grroofing/report-embed/Flashing_Report/WTEC1euMQX2bq9XeyUvEsW80K2uu32xKyjQpdHKGtfn3CQu3K2rWRaDFkmKG4VpWuyePjXEMNB6gPhCF5NUFEuPssAdznFpPKYf6'></iframe>`;

  return (
    <div>
      {/* { yhis.idThis } */}
      {!isDesktop && (
        <iframe
          id='contract'
          title='Sign'
          height='750px'
          frameborder='0'
          allowTransparency='true'
          scrolling='auto'
          src={
            'https://grroofing.app/damjan_geoffreynolds/grroofing/form-embed/Job_Contract_Confirmation/2uga98hNT7R6RnMmpkzZOPrnmqHYMU1eHYEQjSxWAV78m9kMB1ZGKuf0kjusGJwfsqmHDN1Tgvbgj3CaZtQSP0BUfNJxM68rWW9F?contractKeyy=' +
            idParam.idThis
          }
        ></iframe>
      )}
      {isDesktop && (
        <div id='contractDesktop'>
          <img
            src='https://www.geoffreynolds.com.au/assets/img/logo/logo.svg'
            height='60'
          />
          <h3 class='text-center'>
            Please use your phone to view this Contract. For reference feel free
            to use the link sent in the official email, or scan the code below:
          </h3>
          <div id='qrCode_Subbies'>
            <QRCode
              size={256}
              style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
              value={'https://code.grroofing.app/contract/' + idParam.idThis}
              viewBox={`0 0 256 256`}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Contract;
