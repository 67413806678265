import * as React from 'react';

import logo from './logo.svg';
import './App.css';
import { Routes, Route, Link } from 'react-router-dom';
import Contract from './Components/Contract';
import Plan from './Components/Plan';

function App() {
  return (
    <div className='App'>
      <Routes>
        <Route path='/contract/:idThis' element={<Contract />} />
        <Route path='/plan/:url' element={<Plan />} />
      </Routes>
    </div>
  );
}

export default App;
