import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const PlanViewer = props => {
  const thisURL = props.url;
  const thisEncodedURL = props.encodedUrl;

  const navigate = useNavigate();

  useEffect(() => {
    var adobeDCView = new window.AdobeDC.View({
      clientId: 'daed22744d424f798c3c87b9c0d8a749',
      divId: 'adobe-dc-view'
    });

    adobeDCView.previewFile(
      {
        content: {
          location: {
            url: thisURL !== null ? thisURL : ''
          }
        },
        metaData: { fileName: 'Document Preview' }
      },
      { defaultViewMode: 'FIT_PAGE', showAnnotationTools: false }
    );
  }, [thisURL]);
  useEffect(() => {
    console.log('BEFORE ENCODE INSIDE DOC VIEW');
    console.log(thisURL);
  }, [thisURL]);
  return <div id='adobe-dc-view'></div>;
};

export default PlanViewer;
